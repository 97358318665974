import {
  interaction,layer,custom,control,
  Interactopns,Overlays,Controls,
  Map,Layers,Overlay,Util
} from 'react-openlayers';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

      <h1>This is Openlayers in Cloudflare</h1>
      <span role="img" aria-label="dog" style={{marginBottom:"40px"}}>🌏🌏🌏🌏🌏🌏🌏🌏🌏🌏🌏🌏</span>

      <Map view={{center:[0,0], zoom:2}}>
        <Layers>
          <layer.Tile></layer.Tile>
        </Layers>
      </Map>

      </header>
    </div>
  );
}

export default App;
